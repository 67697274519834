import MainTable from "components/Table/MainTable";
import { TABLE_QUICK_TOOLS } from "constant";
import { CODING_RULE_PERSONALIZE } from "constants/personalization";
import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch,  } from "react-redux";
import { showConfirmationModal } from "store/features/general/generalAction";
import Select from "react-select";

import { userCompanyID } from "utils";
import api from "api";
import { customCloneDeep } from "util/customLodash";
import Loader from "components/Loader/Loader";

const CodingRules = () => {
  const dispatch = useDispatch();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [panelOptions, setPanelOptions] = useState([]);
  const quickTools = [TABLE_QUICK_TOOLS.delete];

  useEffect(() => {
    const fetchClientData = async () => {
      if (userCompanyID) {
        const fetchedClient = await api.getClientById(userCompanyID.get());
        setClient(fetchedClient);
      }
    };
    fetchClientData();
  }, [userCompanyID]);

  useEffect(() => {
    const fetchAllTests = async () => {
      if (!client) return;
      setLoading(true);

      const { data } = await api.getLabClientTests();

      const existingIds = client?.mdSetting?.panelList?.map((panel) => panel.id) || [];

      const testList =
        data
          ?.filter((item) => !existingIds.includes(item.id))
          .map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
          })) || [];

      setPanelOptions(testList);
      setLoading(false);
    };
    fetchAllTests();
  }, [client]);

  const deletePanel = async (row) => {
    const cloneClient = customCloneDeep(client);

    cloneClient.mdSetting = cloneClient.mdSetting || {};
    cloneClient.mdSetting.panelList = cloneClient.mdSetting.panelList || [];

    cloneClient.mdSetting.panelList = cloneClient.mdSetting.panelList.filter((item) => item.id !== row.id);
    setClient(cloneClient);

    setPanelOptions((prevOptions) => [...prevOptions, { label: row.name, value: row.id }]);

    await api.updateClient(cloneClient);
  };

  const handleCellClick = (key, row) => {
    if (key === TABLE_QUICK_TOOLS.delete) {
      const confirmationModalData = {
        showModal: true,
        onConfirm: () => deletePanel(row),
        title: "Remove Panel",
        message: "Are you sure you want to remove this panel?",
      };
      dispatch(showConfirmationModal(confirmationModalData));
    }
  };

  const panelData = client?.mdSetting?.panelList || [];

  const handleAddPanel = async (e) => {
    const cloneClient = customCloneDeep(client);

    cloneClient.mdSetting = cloneClient.mdSetting || {};
    cloneClient.mdSetting.panelList = cloneClient.mdSetting.panelList || [];

    cloneClient.mdSetting.panelList.push({ id: e.id, name: e.name });
    setClient(cloneClient);

    setPanelOptions((prevOptions) => prevOptions.filter((option) => option.value !== e.id));

    await api.updateClient(cloneClient);
  };

  return (
    <Row>
      <Col md={12}>
        {loading && <Loader />}
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <div className="d-flex justify-content-between align-items-center px-2 pe-4">
              <Card.Title
                as="h4"
                style={{
                  marginBottom: 10,
                  fontWeight: "bold",
                }}
              >
                Combining Orders
              </Card.Title>
              <div style={{ width: "40%" }}>
                <Select
                  options={panelOptions}
                  blurInputOnSelect={true}
                  menuPlacement="auto"
                  placeholder={"Select panel to add in list"}
                  className="w-100"
                  onChange={handleAddPanel}
                />
              </div>
            </div>
          </Card.Header>
          <Card.Body className="table-full-width desktop-noScroll">
            <MainTable
              columns={CODING_RULE_PERSONALIZE}
              rows={panelData}
              //   flipSort={flipSort}
              //   sortBy={sortBy}
              //   sortDescending={sortDescending}
              widthToSkip={200}
              //   draggable
              //   resizable
              //   personalisationKey={"rederingProviders"}
              handleCellClick={handleCellClick}
              //   customColumnCellRenderer={renderTd}
              // handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
              tools={quickTools}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default CodingRules;
